import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/utils/cn";

// Variants for the label style
const labelVariants = cva(
  "font-medium text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
);

interface LabelProps
  extends React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>,
    VariantProps<typeof labelVariants> {
  tooltip?: string; // Optional help text prop
}

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  LabelProps
>(({ className, tooltip: helpText, children, ...props }, ref) => (
  <div className="flex items-center gap-1">
    <LabelPrimitive.Root
      ref={ref}
      className={cn(labelVariants(), className)}
      {...props}
    >
      {children}
    </LabelPrimitive.Root>

    {helpText && (
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="inline-block">
            <QuestionMarkCircledIcon className="h-4 w-4 text-muted-foreground" />
          </span>
        </TooltipTrigger>
        <TooltipContent side="top" align="center">
          {helpText}
        </TooltipContent>
      </Tooltip>
    )}
  </div>
));

Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
