import { Header } from "./header";

interface Props {
  children: React.ReactNode;
}
export function Layout({ children }: Props) {
  return (
    <div className="h-screen w-screen text-black ">
      <Header />
      <div className="container mx-auto gap-5 px-3 py-4 md:px-0">
        <div className="">{children}</div>
      </div>
    </div>
  );
}
