// TODO

import { api } from "@/lib/api";
import { useQuery } from "@tanstack/react-query";

const QUERY_KEY = "marketData";

export const useMarketData = () => {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      const res = await api.market.info.get();
      return res.data;
    },
    staleTime: 1000 * 60 * 5,
  });
};
