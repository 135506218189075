import { Outlet, createRootRoute } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { WalletProvider } from "@tronweb3/tronwallet-adapter-react-hooks";
import { Layout } from "../layout";
import { ThemeProvider } from "next-themes";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "@/lib/queryClient";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Toaster } from "@/components/ui/sonner";
import { DialogProvider } from "@/contexts/dialogContext";
import { TooltipProvider } from "@/components/ui/tooltip";

export const Route = createRootRoute({
  component: RootComponent,
});

function RootComponent() {
  return (
    <QueryClientProvider client={queryClient}>
      <WalletProvider
        disableAutoConnectOnLoad={true}
        autoConnect={false}
        // onConnect={handleConnect}
      >
        <ThemeProvider attribute="class">
          <TooltipProvider delayDuration={200}>
            <DialogProvider>
              <Layout>
                <Outlet />
              </Layout>
              <Toaster richColors />
            </DialogProvider>
          </TooltipProvider>
          <TanStackRouterDevtools />
          <ReactQueryDevtools />
        </ThemeProvider>
      </WalletProvider>
    </QueryClientProvider>
  );
}
