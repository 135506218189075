import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { Button } from "./ui/button";
import { toast } from "sonner";

export const ConnectButton: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { connect, connecting } = useWallet();

  const handleConnect = async () => {
    try {
      await connect();
      toast.success("Wallet connected");
    } catch (error) {
      toast.error("Error connecting wallet");
      console.error("Error connecting wallet:", error);
    }
  };

  return (
    <Button
      variant="outline"
      onClick={handleConnect}
      className={`flex items-center gap-2 ${className}`}
      disabled={connecting}
    >
      <img src="/tronlink.jpg" alt="TronLink" className="h-5 rounded-sm" />
      {connecting ? "Connecting..." : "Connect Wallet"}
    </Button>
  );
};
