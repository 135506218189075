// src/components/dialog/transactionDialog.tsx

import type React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

interface TransactionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  icon: React.ReactNode;
  title: string;
  children: React.ReactNode;
  confirmText: string;
  confirmDisabled?: boolean;
}

export const LayoutDialog: React.FC<TransactionDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
  icon,
  title,
  children,
  confirmText,
  confirmDisabled,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <div className="mx-auto mb-4 rounded-full bg-primary p-4">{icon}</div>
          <DialogTitle className="text-center font-bold text-2xl">
            {title}
          </DialogTitle>
        </DialogHeader>
        {children}
        <div className="mt-4 flex justify-between">
          <Button
            className="mr-2 flex-grow"
            onClick={onConfirm}
            disabled={confirmDisabled}
          >
            {confirmText}
          </Button>
          <Button
            variant="outline"
            className="ml-2 flex-grow"
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
