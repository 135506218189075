import type * as React from "react";
import { Label } from "./ui/label";

interface Props {
  label: string;
  tooltip?: string;
  children: React.ReactNode;
}

export function FormItem(props: Props) {
  return (
    <div className="space-y-2">
      <Label tooltip={props.tooltip}>{props.label}</Label>
      {props.children}
    </div>
  );
}
