import { treaty } from "@elysiajs/eden";
import type { App } from "../../../api/src";
import { env } from "@/env";
import SuperJSON from "superjson";

export const { api } = treaty<App>(env.VITE_API_URL, {
  async onResponse(response) {
    return SuperJSON.parse(await response.text());
  },
});
